<template>
  <div>
    <div v-if="!loadingBusiness && business">
      <b-tabs>
        <b-tab title="Business Setting" active @click="onTabClick('setting')">
          <div class="card">
            <div class="card-body">
              <ValidationObserver v-slot="{ handleSubmit, invalid, touched }" ref="updateForm">
                <form class="authentication-form" @submit.prevent="handleSubmit(updateBusiness)">
                  <div class="row">
                    <div class="col-md-6">
                      <TextInput v-model="business.owner.first_name" name="first_name" rules="required"
                        label="First Name" />
                    </div>
                    <div class="col-md-6">
                      <TextInput v-model="business.owner.last_name" name="last_name" rules="required" label="Last Name" />
                    </div>
                    <div class="col-md-6">
                      <TextInput v-model="business.name" name="name" rules="required" label="Company Name" />
                    </div>
                    <div class="col-md-6">
                      <TextInput v-model="business.owner.email" name="owner.email" rules="required|email" label="Email" />
                    </div>
                    <div class="col-md-6">
                      <PhoneInput v-model="business.owner.phone_number" name="Phone Number" label="Phone Number"
                        rules="required|phone" :disabled-fetching-country="true" />
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Currency</label>
                        <select v-model="business.currency" class="form-control">
                          <option v-for="currency in currencies" :key="currency.name" :value="currency.name">{{
                            currency.label }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Date format</label>
                        <select v-model="business.date_format" class="form-control">
                          <option v-for="datetime in dateFormats" :key="datetime" :value="datetime">{{ datetime }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <SelectInput v-model="business.timezone" :options="timezones" label="Timezone" />
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Messages Reset Day <span v-b-tooltip.hover class="cursor-pointer"
                            title="You can set the day of the month when you want the message limits to reset"><i
                              class="uil uil-question-circle"></i></span></label>
                        <SelectInput v-model="business.reset_at" :options="days" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Created At</label>
                        <flat-pickr v-model="business.created_at" :config="dateTimePicker" class="form-control"
                          placeholder="Pick a date" :disabled="true"></flat-pickr>
                      </div>
                    </div>
                    <div class="col-md-12 mb-3"></div>
                    <div class="col-md-6">
                      <label>Message Limit</label>
                      <div class="mb-2 d-flex">
                        <div class="pt-2">
                          <b-form-checkbox v-model="business.message_limit" name="check-button" switch inline>
                          </b-form-checkbox>
                        </div>
                        <div class="flex-fill">
                          <TextInput v-model="business.no_messages" :readonly="!business.message_limit" type="number"
                            name="no_messages" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <label>Contact Limit</label>
                      <div class="mb-2 d-flex">
                        <div class="pt-2">
                          <b-form-checkbox v-model="business.contact_limit" name="check-button" switch inline>
                          </b-form-checkbox>
                        </div>
                        <div class="flex-fill">
                          <TextInput v-model="business.no_contacts" :readonly="!business.contact_limit" type="number"
                            name="no_contacts" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-md-12 mb-2">
                      <h5>Features</h5>
                    </div>
                    <div v-if="agencyLiveChatActive" class="col-md-6">
                      <div class="mb-4">
                        <div class="pt-2">
                          <span class="pr-2" style="font-weight:600;">Inactive</span>
                          <b-form-checkbox v-model="business.is_active_livechat" name="check-button" switch inline>
                            Active - Livechat
                          </b-form-checkbox>
                        </div>
                      </div>
                    </div>
                    <div v-if="agencyLoopActive" class="col-md-6">
                      <div class="mb-4">
                        <div class="pt-2">
                          <span class="pr-2" style="font-weight:600;">Inactive</span>
                          <b-form-checkbox v-model="business.is_active_loop" name="check-button" switch inline>
                            Active - Loop
                          </b-form-checkbox>
                        </div>
                      </div>
                    </div>
                    <div v-if="agencyLiveChatActive" class="col-md-6">
                      <div class="mb-4">
                        <div class="pt-2">
                          <span class="pr-2" style="font-weight:600;">Inactive</span>
                          <b-form-checkbox v-model="business.is_active_mms" name="check-button" switch inline>
                            Active - MMS
                          </b-form-checkbox>
                        </div>
                      </div>
                    </div>
                    <div v-if="user && user.agency.is_enable_import_contact" class="col-md-6">
                      <div class="mb-4">
                        <div class="pt-2">
                          <span class="pr-2" style="font-weight:600;">Inactive</span>
                          <b-form-checkbox v-model="business.is_enable_import_contact" name="check-button" switch inline>
                            Active - Import Contact
                          </b-form-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                  <b-form-group>
                    <div class="d-flex justify-content-between align-items-center mt-5">
                      <b-button variant="primary" :disabled="loadingUpdate || (invalid && touched)" type="submit">
                        <b-spinner v-if="loadingUpdate" small />
                        <span v-else>Update</span>
                      </b-button>
                      <b-button variant="primary" :disabled="loadingImpersonate" @click="impersonate">
                        <b-spinner v-if="loadingImpersonate" small />
                        <span v-else>Log in <i class="uil uil-sign-out-alt"></i></span>
                      </b-button>
                    </div>
                  </b-form-group>
                </form>
              </ValidationObserver>
            </div>
          </div>
          <div v-if="!isTemplate" class="card">
            <div class="card-body">
              <ValidationObserver v-slot="{ handleSubmit, invalid, touched }" ref="passwordForm">
                <form class="authentication-form" @submit.prevent="handleSubmit(changePassword)">
                  <div class="row">
                    <div class="col-md-6">
                      <TextInput v-model="password.password" name="password" rules="required|confirmed:confirm_password"
                        label="Password" type="password" />
                    </div>
                    <div class="col-md-6">
                      <TextInput v-model="password.password_confirmation" vid="confirm_password" name="confirm_password"
                        rules="required" label="Confirm Password" type="password" />
                    </div>
                  </div>
                  <b-form-group>
                    <b-button variant="primary" :disabled="loadingPassword || (invalid && touched)" type="submit">
                      <b-spinner v-if="loadingPassword" small />
                      <span v-else>Change</span>
                    </b-button>
                  </b-form-group>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </b-tab>
        <b-tab v-if="showSubscriptionTab" title="Subscription" :active="tab === 'subscription'" @click="onTabClick('subscription')">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <h5>SMS registration</h5>
                  <div v-if="!isTollfree" class="d-block">
                    <div class="mt-4"><label style="width: 150px">Status:</label> <span class="font-weight-bold"
                        :class="{ 'text-danger': a2pSubscriptionStatus === 'canceled', 'text-primary': a2pSubscriptionStatus === 'active' }">{{
                          a2pSubscriptionStatus | capitalize }}</span></div>
                    <div v-if="a2pSubscriptionStatus === 'canceled'"><label style="width: 150px">Reason:</label> <span class="font-weight-bold">Payment Method Failed</span></div>
                    <div v-if="terminateAt"><label style="width: 150px">Deactivated on:</label> <span class="font-weight-bold">{{
                      terminateAt }}</span></div>
                  </div>
                  <div><label style="width: 150px">Type:</label> <span class="font-weight-bold">{{
                    isTollfree ? 'TollFree' : 'Local' }}</span></div>
                  <div v-if="!isTollfree" class="d-block">
                    <div class="mt-2">To start sending SMS text messages please reactivate your monthly $20 subscription for sending SMS messages.</div>
                    <b-button v-if="a2pSubscriptionStatus !== 'active'" class="mt-5" variant="primary"
                      :disabled="processing" @click="onSubscribeA2p">
                      <b-spinner v-if="processing" small />
                      <span v-else>Reactivate</span>
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab v-if="isTemplate" title="Template Description" :active="tab === 'desc'" @click="onTabClick('desc')">
          <div class="card">
            <div class="card-body">
              <ValidationObserver v-slot="{ handleSubmit, invalid, touched }" ref="updateForm">
                <form class="authentication-form" @submit.prevent="handleSubmit(updateBusiness)">
                  <div class="row">
                    <div class="col-md-4">
                      <UploadImage v-model="business.template_image" :business-id="business.id" label="Template image" />
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Description</label>
                    <editor v-model="business.template_description" :api-key="tinyMCEKey" :init="{ height: 250 }"
                      :toolbar="customToolbar" :plugins="plugins" />
                  </div>

                  <b-form-group>
                    <b-button variant="primary" :disabled="loadingUpdate || (invalid && touched)" type="submit">
                      <b-spinner v-if="loadingUpdate" small />
                      <span v-else>Update</span>
                    </b-button>
                  </b-form-group>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </b-tab>
        <b-tab v-if="isTemplate" title="Resources" :active="tab === 'resource'" @click="onTabClick('resource')">
          <div class="card">
            <div class="card-body">
              <ValidationObserver v-slot="{ handleSubmit, invalid, touched }" ref="updateForm">
                <form class="authentication-form" @submit.prevent="handleSubmit(updateBusiness)">
                  <div class="form-group">
                    <label>Content</label>
                    <editor v-model="business.template_content" :api-key="tinyMCEKey" :init="{ height: 600 }"
                      :toolbar="customToolbar" :plugins="plugins" />
                  </div>
                  <b-form-group>
                    <b-button variant="primary" :disabled="loadingUpdate || (invalid && touched)" type="submit">
                      <b-spinner v-if="loadingUpdate" small />
                      <span v-else>Update</span>
                    </b-button>
                  </b-form-group>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <div v-else class="text-center">
      <b-spinner></b-spinner>
    </div>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import moment from 'moment-timezone'

export default {
  components: {
    'editor': Editor
  },

  data() {
    return {
      countries: [{ key: 'US', label: 'United States of America' }, { key: 'CA', label: 'Canada' }],
      loadingBusiness: false,
      business: null,
      loadingUpdate: false,
      loadingPassword: false,
      password: {
        password: '',
        password_confirmation: '',
      },
      dateTimePicker: {
        enableTime: false,
        dateFormat: 'Y-m-d',
      },
      days: [],
      tinyMCEKey: null,
      plugins: 'imagetools image autolink link media lists',
      customToolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | image media',
      loadingImpersonate: false,
      processing: false,
      tab: null,
    }
  },

  computed: {
    isTemplate() {
      return this.user && this.user.agency && this.user.agency.is_template
    },

    isBandwidthUser() {
      return this.businessOwner && this.businessOwner.use_bandwidth
    },

    showSubscriptionTab() {
      return !this.isTemplate && this.isBandwidthUser && this.a2pSubscriptionStatus !== 'N/A'
    },

    agencyLiveChatActive() {
      const user = this.$store.getters['auth/user'];
      if (user) {
        return user.agency && user.agency.is_enabled_livechat
      }
      return false
    },
    agencyLoopActive() {
      const user = this.$store.getters['auth/user'];
      if (user) {
        return user.agency && user.agency.is_enabled_loop
      }
      return false
    },
    timezones() {
      return this.$store.getters['app/timezones']
    },

    currencies() {
      return this.$store.getters['app/currencies']
    },

    dateFormats() {
      return this.$store.getters['app/dateFormats']
    },

    onlyCountries() {
      return [this.business.country]
    },

    user() {
      return this.$store.getters['auth/user']
    },

    businessOwner() {
      return this.business && this.business.owner
    },

    a2pSubscriptionStatus() {
      return this.business && this.business.a2p_subscription_status || 'N/A'
    },

    terminateAt() {
      let res = null
      if (this.business && this.business.a2p_subscription_end_at) {
        res = moment(this.business.a2p_subscription_end_at).add(5, 'days').format('MMM Do');
      }
      return res
    },

    isTollfree() {
      return this.business && this.business.is_tollfree
    }
  },

  mounted() {
    this.days = Array.from(Array(31).keys()).map((item) => {
      return {
        label: item + 1,
        value: item + 1
      }
    })

    this.getBusiness()
    if (this.$route.query.tab)
      this.tab = this.$route.query.tab
  },

  methods: {
    onTabClick(index) {
      this.tab = index
      this.$router.push({ name: 'agency.businesses.edit', params: { id: this.business.id },  query: {tab: index} })
    },
    onSubscribeA2p() {
      this.processing = true
      let params = {
        'business_id': this.business.id
      }
      this.$store
        .dispatch('subscription/subscribeA2p', params)
        .then(() => {
          this.business.a2p_subscription_status = 'active'
          this.business.a2p_subscription_end_at = null
          this.processing = false
        })
        .catch(() => {
          this.processing = false
        })
    },

    updateBusiness() {
      this.loadingUpdate = true

      this.$store
        .dispatch('business/update', this.business)
        .then(() => {
          this.loadingUpdate = false
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.updateForm.setErrors(err.response.data.errors)
          }
          this.loadingUpdate = false
        })
    },

    getBusiness() {
      this.loadingBusiness = true

      this.$store
        .dispatch('business/find', this.$route.params.id)
        .then((business) => {
          business.message_limit = business.no_messages != null
          business.contact_limit = business.no_contacts != null
          this.business = business
          this.loadingBusiness = false
        })
        .catch(() => {
          this.$router.push({ name: 'agency.businesses.index' })
          this.loadingBusiness = false
        })
    },

    changePassword() {
      this.loadingPassword = true

      this.$store
        .dispatch('user/changePassword', {
          ...this.password,
          user_id: this.business.owner.id,
        })
        .then(() => {
          this.password = {}
          this.loadingPassword = false
          this.$refs.passwordForm.reset()
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.passwordForm.setErrors(err.response.data.errors)
          }
          this.loadingPassword = false
        })
    },

    impersonate() {
      this.loadingImpersonate = true
      const user = this.business.owner
      if (user) {
        this.$store
          .dispatch('auth/impersonate', user.id)
          .then((res) => {
            this.$store
              .dispatch('auth/getProfile')
              .then((user) => {
                this.$router.push('/' + user.role.toLocaleLowerCase())
                this.loadingImpersonate = false
              })
              .catch(() => {
                this.loadingImpersonate = false
              })
          })
          .catch(() => {
            this.loadingImpersonate = false
          })
      }
    },
  },
}
</script>
